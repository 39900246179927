import iconTransaction from 'assets/images/iconTransaction.svg';

function Transaction() {
  return (
    <div className="w-full bg-[#F0F7FC]">
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto py-[50px] md:py-[100px] ">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
          <div
            className="col-span-3 md:pr-16 order-2 md:order-1 flex flex-col justify-center mt-10 md:mt-0 max-w-[600px]"
            data-aos="fade-right"
          >
            <h2 className="font-bold uppercase text-lg">
              GIAO DỊCH MINH BẠCH, NHANH CHÓNG VÀ AN TOÀN 24/7
            </h2>
            <div className="mt-2">
              Chuyển đổi giữa BOOK và tiền mặt 24/7 nhờ tính bảo mật và nhanh chóng của blockchain.
            </div>
          </div>
          <div
            className="col-span-2 order-1 md:order-2"
            data-aos="fade-left"
          >
            <img
              alt="transaction"
              src={iconTransaction}
              className="max-w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
