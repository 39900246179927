import { useState } from 'react';
import faqBorderHeader from 'assets/images/faqBorderHeader.svg';
import iconFaq1 from 'assets/images/iconFaq1.svg';
import iconFaq2 from 'assets/images/iconFaq2.svg';
import iconFaq3 from 'assets/images/iconFaq3.svg';
import iconFaq4 from 'assets/images/iconFaq4.svg';
import iconFaq5 from 'assets/images/iconFaq5.svg';
import style from './style.module.scss';

type IAccordion = {
  id: number;
  title: string;
  content: string;
  icon: any;
  active: number | undefined;
  setActive: (active: number) => void;
};

function AccordionItem({ id, title, icon, content, active, setActive }: IAccordion) {
  return (
    <div className="accordion-item mb-5">
      {/* eslint-disable jsx-a11y/no-static-element-interactions */
      /* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        className="accordion-title flex items-center cursor-pointer font-medium"
        onClick={() => setActive(id)}
      >
        <div className="w-[40px] h-[40px] mr-2 basis-[40px] grow-0 shrink-0">
          <img
            src={icon}
            width="150px"
            height="150px"
            alt={title}
          />
        </div>
        <div className="font-semibold">{title}</div>
        <div className="ml-3">
          {active === id ? (
            <span className={`${style.triangleDown} rotate-180`} />
          ) : (
            <span className={style.triangleDown} />
          )}
        </div>
      </div>
      {active === id && <div className="accordion-content pl-[50px]">{content}</div>}
    </div>
  );
}

function Faq() {
  const [active, setActive] = useState<number>();

  const faqs = [
    {
      id: 1,
      title: 'BOOK stable token là gì?',
      content:
        'BOOK là một token được xây dựng trên blockchain có giá trị ổn định và luôn giữ tỉ lệ quy đổi bền vững 1:10.000 đồng.',
      icon: iconFaq1,
    },
    {
      id: 2,
      title: 'Tại sao tôi lại cần sử dụng BOOK?',
      content:
        'Khi Fintech và RealFi phát triển, kéo theo đó là nhu cầu sử dụng một token có giá trị ổn định nhưng tiền mặt lại không thể tích hợp trên blockchain, do đó, một stable token luôn giữ tỉ lệ 1:10.000 đồng như BOOK là giải pháp tối ưu để làm phương tiện trao đổi giá trị thay thế tiền mặt trên blockchain.',
      icon: iconFaq2,
    },
    {
      id: 3,
      title: 'Tôi có thể nhận và rút  BOOK bằng cách nào?',
      content:
        'Các mã thông báo BOOK có thể được tạo, truy cập và sử dụng một cách dễ dàng. Người dùng chuyển tiền mặt (đồng) vào tài khoản ngân hàng của công ty FMC. Hệ thống quản lý FMC BOOK xác nhận có giá trị chuyển tiền mặt vào và phát hành (mint) số lượng token BOOK tương ứng vào Pool. (Đơn vị tính 10.000 đồng/BOOK). Sau đó token BOOK tương ứng sẽ được chuyển vào ví của người dùng.',
      icon: iconFaq3,
    },
    {
      id: 4,
      title: 'Tôi có thể kiểm tra số lượng BOOK  đang lưu thông hay không?',
      content: `Bất cứ lúc nào, người sở hữu BOOK đều có thể truy cập trang web của chúng tôi để kiểm tra số lượng của mã thông báo BOOK đang nắm giữ và số lượng tiền mặt sẵn sàng trong hệ thống để cash-out. 
      `,
      icon: iconFaq4,
    },
    {
      id: 5,
      title: 'Mất bao lâu để thực hiện một giao dịch nhận hoặc rút BOOK?',
      content:
        'Khi người dùng thông báo số lượng BOOK cần cash-out, hệ thống quản lý FMC BOOK xác nhận có yêu cầu chuyển trả tiền mặt và trừ (burn) số lượng token BOOK tương ứng từ Pool. Tài khoản ngân hàng Việt Nam của công ty FMC BOOK xử lý lệnh chuyển tiền và tính phí tạo mã thông báo BOOK theo biểu phí. Sau đó Người dùng sẽ nhận được lượng tiền mặt tương ứng từ tài khoản ngân hàng của FMC BOOK.',
      icon: iconFaq5,
    },
  ];

  return (
    <div className="w-full bg-white">
      <div className="container px-4 md:px-0 mx-auto pb-[180px]  ">
        <div
          className={style.header}
          style={{ backgroundImage: `url(${faqBorderHeader})` }}
        >
          <div className="header-text">FAQ</div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1500"
        >
          <div className="accordion mt-5">
            {faqs.map(({ id, title, icon, content }) => (
              <AccordionItem
                title={title}
                content={content}
                icon={icon}
                id={id}
                key={id}
                setActive={setActive}
                active={active}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
