import { Hero, ConversionRate, Exchange, Transaction, Faq, Partner } from 'components/home';

function Home() {
  return (
    <div>
      <div>
        <Hero />
      </div>
      <div>
        <ConversionRate />
      </div>
      <div>
        <Transaction />
      </div>
      <div>
        <Exchange />
      </div>
      <div>
        <Faq />
      </div>
      <div>
        <Partner />
      </div>
    </div>
  );
}

export default Home;
