import iconShakeHands from 'assets/images/iconSharkeHand.png';
import logoKardia from 'assets/images/partner/kardiachain.svg';
import partnerBorderHeader from 'assets/images/borderShakeHands.svg';
import style from './style.module.scss';

// type IPartner = {
//   id: number;
//   title: string;
// };

function PartnerItem() {
  return (
    <div className="bg-white rounded-3xl p-3 mb-5 table align-middle">
      <div className="w-[133px] h-[24px] mx-auto table-cell align-middle p-3">
        <img
          src={logoKardia}
          className="w-auto h-[24px] mx-auto"
          alt="logo"
        />
      </div>
    </div>
  );
}

function Partner() {
  const partnersData = [
    {
      id: 1,
      title: 'BOOK token là gì?',
    },
    {
      id: 2,
      title: 'Tôi có thể kiểm tra số lượng BOOK  đang lưu thông hay không?',
    },
    {
      id: 3,
      title: 'Làm cách nào để sở hữu BOOK token?',
    },
    {
      id: 4,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
    {
      id: 5,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
    {
      id: 6,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
  ];

  return (
    <div
      className="w-full"
      style={{
        background:
          'linear-gradient(180deg, #C8E7F9 -0.09%, #E6F4FC 39.94%, #F8FCFE 74.96%, #FFFFFF 99.98%)',
      }}
    >
      <div>
        <div
          className={style.iconHeader}
          style={{ backgroundImage: `url(${partnerBorderHeader})` }}
        >
          <div className="w-[200px] relative bottom-[60px]">
            <img
              className="mx-auto"
              src={iconShakeHands}
              alt="icon shake"
            />
          </div>
        </div>
      </div>
      <div className="container px-4 md:px-0 text-center mx-auto relative pt-[50px] pb-[50px]">
        <h2 className="text-lg uppercase text-center text-[#2C67AA] font-semibold mb-10">
          Đối tác
        </h2>
        <div
          className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 max-w-[800px] mx-auto"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1500"
        >
          {partnersData.map(({ id }) => (
            <PartnerItem key={id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partner;
