import iconConvert from 'assets/images/iconConvert.svg';

function ConversionRate() {
  return (
    <div
      className="w-full"
      style={{
        background:
          'linear-gradient(180deg, #EBEBEB 0%, rgba(246, 246, 246, 0.479167) 37.5%, rgba(255, 255, 255, 0) 75%)',
      }}
    >
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto py-[50px] md:py-[100px] ">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
          <div className="col-span-2">
            <img
              src={iconConvert}
              className="max-w-full h-auto"
              alt="convert"
            />
          </div>
          <div className="col-span-3 md:pl-16 flex flex-col justify-center mt-10 md:mt-0 max-w-[500px]">
            <h2 className="font-bold text-lg uppercase">TỈ LỆ QUY ĐỔI BỀN VỮNG 1:10.000 ĐỒNG</h2>
            <div className="mt-2">Luôn ổn định và được hỗ trợ đầy đủ bởi Việt Nam đồng theo tỉ lệ 1:10.000 đồng.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversionRate;
