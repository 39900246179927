import iconBook from 'assets/images/icon-book.png';
import style from './style.module.scss';

function Hero() {
  return (
    <div className={`${style.wrapper} w-full bg-[#01265e] relative bg-no-repeat`}>
      <div
        className="container px-4 md:px-0 pt-10 md:pt-0 flex flex-wrap justify-between md:items-center mx-auto min-h-[790px] md:min-h-[600px] text-white"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className="absolute bottom-[200px] left-[50%] ml-[-146px] md:hidden">
          <img
            src={iconBook}
            alt="Book"
            className="w-[280px]"
          />
        </div>
        <div className="grid md:grid-cols-3 gap-4 w-full">
          <div className="col-span-2 ">
            <h1 className="font-semibold text-[33px] leading-tight">
              BOOK - STABLE TOKEN DÀNH CHO FINTECH VÀ REALFI
            </h1>
            <div className="mt-2">
              Cầu nối mang giá trị tài sản thế giới thực vào thế giới tài sản mã hoá
            </div>
            <div className="mt-4">
              <a
                className="!bg-[#4B78BD] hover:bg-slate-700 focus:outline-none  text-white py-2 px-6 rounded-lg  inline-block dark:bg-sky-500 dark:highlight-white/20 dark:hover:bg-sky-400"
                href="/"
              >
                Mua BOOK
              </a>
            </div>
          </div>
          <div className="col-span-1 " />
        </div>
      </div>
    </div>
  );
}

export default Hero;
