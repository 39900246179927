import iconExchange from 'assets/images/iconExchange.svg';

function Exchange() {
  return (
    <div
      className="w-full "
      style={{
        background:
          'linear-gradient(90deg, #91C3FA 0%, #9FCDFA 10%, #C5E7FC 30%, #DCF7FD 50%, #E5FDFE 70%, #BFDDF3 100%)',
      }}
    >
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto pb-[100px] md:pb-0">
        <div className="md:flex md:gap-4 w-full md:left-[-80px] relative">
          <div className="w-[800px] max-w-full relative">
            <img
              src={iconExchange}
              alt="exchange"
            />
          </div>
          <div
            className=" flex flex-col justify-center"
            data-aos="fade-left"
          >
            <h2 className="font-bold uppercase text-lg">
              PHƯƠNG TIỆN TRAO ĐỔI GIÁ TRỊ THAY THẾ TIỀN MẶT TRÊN BLOCKCHAIN
            </h2>
            <div className="mt-2">
              Tạo điều kiện để tiếp cận nguồn vốn trong thế giới tài sản thực trực tiếp trên
              blockchain.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exchange;
